
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'books'
  | 'books2'
  | 'comments'
  | 'library2'
  | 'magazine'
  | 'menu'
  | 'new-testament'
  | 'old-testament'
  | 'people'
  | 'poem'
  | 'question'
  | 'search'
  | 'steps'
  | 'table'
  | 'topics'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.LAO0ZcwyDY.svg"}
